// external components
import { useEffect, useRef, useState } from "react";
import { SketchPicker } from "react-color";
import { toast } from "react-toastify";

// internal components
import { GetContextApi } from "../../../../ContextApi";
import "./CusHeaderPage.css";

const CusHeaderPage = () => {
	// for getting input-filed value
	const [getImg, setImg] = useState("");

	// for preview start
	const [getPreviewImg, setPreviewImg] = useState("");

	const imgHandler = (event) => {
		setImg(event.target.files[0]);
		const reader = new FileReader();
		reader.onload = () => {
			if (reader.readyState === 2) {
				setPreviewImg(reader.result);
			}
		};
		reader.readAsDataURL(event.target.files[0]);
	};
	// for preview end

	// upload that img on server start
	const submitHandler = async () => {
		try {
			const formData = new FormData();
			formData.append("file", getImg);

			const response = await fetch("/customize/main-logo", {
				method: "PUT",
				body: formData
			});

			const result = await response.json();

			if (response.status === 200) {
				toast.success(result.message, {
					position: "top-right",
					theme: "colored",
					autoClose: 1500
				});

				setPreviewImg("");
			} else {
				toast.error(result.error, {
					position: "top-right",
					theme: "colored",
					autoClose: 3000
				});
			}
		} catch (error) {
			toast.error(error.message, {
				position: "top-right",
				theme: "colored",
				autoClose: 3000
			});
		}
	};
	// upload that img on server end

	// for getting projects all colors
	const { colorStates, dispatch } = GetContextApi();

	// for all color-picker toggle start
	const [menuBgT, setMenuBgT] = useState(false);
	const [adminForeT, setAdminForeT] = useState(false);
	const [adminBgT, setAdminBgT] = useState(false);
	const [advisorsForeT, setAdvisorsForeT] = useState(false);
	const [advisorsBgT, setAdvisorsBgT] = useState(false);
	const [studentsForeT, setStudentsForeT] = useState(false);
	const [studentsBgT, setStudentsBgT] = useState(false);
	const [notificationUpColT, setNotificationUpColT] = useState(false);
	const [notificationDownColT, setNotificationDownColT] = useState(false);
	const [dropdownForeT, setDropdownForeT] = useState(false);
	const [dropdownBgT, setDropdownBgT] = useState(false);
	// for all color-picker toggle end

	// for close color-picker start

	// for menu-bar background-color
	const menuBgRef = useRef();

	const handleClickOutsideMenuBg = (e) => {
		if (!menuBgRef.current?.contains(e.target)) {
			setMenuBgT(false);
		}
	};

	useEffect(() => {
		document.addEventListener("mousedown", handleClickOutsideMenuBg);
		return () =>
			document.removeEventListener("mousedown", handleClickOutsideMenuBg);
	}, []);

	// for admin foreground-color
	const adminForeRef = useRef();

	const handleClickOutsideAdminFore = (e) => {
		if (!adminForeRef.current?.contains(e.target)) {
			setAdminForeT(false);
		}
	};

	useEffect(() => {
		document.addEventListener("mousedown", handleClickOutsideAdminFore);
		return () =>
			document.removeEventListener("mousedown", handleClickOutsideAdminFore);
	}, []);

	// for admin background-color
	const adminBgRef = useRef();

	const handleClickOutsideAdminBg = (e) => {
		if (!adminBgRef.current?.contains(e.target)) {
			setAdminBgT(false);
		}
	};

	useEffect(() => {
		document.addEventListener("mousedown", handleClickOutsideAdminBg);
		return () =>
			document.removeEventListener("mousedown", handleClickOutsideAdminBg);
	}, []);

	// for advisor foreground-color
	const advisorsForeRef = useRef();

	const handleClickOutsideAdvisorsFore = (e) => {
		if (!advisorsForeRef.current?.contains(e.target)) {
			setAdvisorsForeT(false);
		}
	};

	useEffect(() => {
		document.addEventListener("mousedown", handleClickOutsideAdvisorsFore);
		return () =>
			document.removeEventListener("mousedown", handleClickOutsideAdvisorsFore);
	}, []);

	// for advisors background-color
	const advisorsBgRef = useRef();

	const handleClickOutsideAdvisorsBg = (e) => {
		if (!advisorsBgRef.current?.contains(e.target)) {
			setAdvisorsBgT(false);
		}
	};

	useEffect(() => {
		document.addEventListener("mousedown", handleClickOutsideAdvisorsBg);
		return () =>
			document.removeEventListener("mousedown", handleClickOutsideAdvisorsBg);
	}, []);

	// for students foreground-color
	const studentsForeRef = useRef();

	const handleClickOutsideStudentsFore = (e) => {
		if (!studentsForeRef.current?.contains(e.target)) {
			setStudentsForeT(false);
		}
	};

	useEffect(() => {
		document.addEventListener("mousedown", handleClickOutsideStudentsFore);
		return () =>
			document.removeEventListener("mousedown", handleClickOutsideStudentsFore);
	}, []);

	// for students background-color
	const studentsBgRef = useRef();

	const handleClickOutsideStudentsBg = (e) => {
		if (!studentsBgRef.current?.contains(e.target)) {
			setStudentsBgT(false);
		}
	};

	useEffect(() => {
		document.addEventListener("mousedown", handleClickOutsideStudentsBg);
		return () =>
			document.removeEventListener("mousedown", handleClickOutsideStudentsBg);
	}, []);

	// for notification gradient up-color
	const notificationUpColRef = useRef();

	const handleClickOutsideNotificationUpCol = (e) => {
		if (!notificationUpColRef.current?.contains(e.target)) {
			setNotificationUpColT(false);
		}
	};

	useEffect(() => {
		document.addEventListener("mousedown", handleClickOutsideNotificationUpCol);
		return () =>
			document.removeEventListener(
				"mousedown",
				handleClickOutsideNotificationUpCol
			);
	}, []);

	// for notification gradient down-color
	const notificationDownColRef = useRef();

	const handleClickOutsideNotificationDownCol = (e) => {
		if (!notificationDownColRef.current?.contains(e.target)) {
			setNotificationDownColT(false);
		}
	};

	useEffect(() => {
		document.addEventListener(
			"mousedown",
			handleClickOutsideNotificationDownCol
		);
		return () =>
			document.removeEventListener(
				"mousedown",
				handleClickOutsideNotificationDownCol
			);
	}, []);

	// for dropdown foreground-color
	const dropdownForeRef = useRef();

	const handleClickOutsideDropdownFore = (e) => {
		if (!dropdownForeRef.current?.contains(e.target)) {
			setDropdownForeT(false);
		}
	};

	useEffect(() => {
		document.addEventListener("mousedown", handleClickOutsideDropdownFore);
		return () =>
			document.removeEventListener("mousedown", handleClickOutsideDropdownFore);
	}, []);

	// for dropdown background-color
	const dropdownBgRef = useRef();

	const handleClickOutsideDropdownBg = (e) => {
		if (!dropdownBgRef.current?.contains(e.target)) {
			setDropdownBgT(false);
		}
	};

	useEffect(() => {
		document.addEventListener("mousedown", handleClickOutsideDropdownBg);
		return () =>
			document.removeEventListener("mousedown", handleClickOutsideStudentsBg);
	}, []);
	// for close color-picker end

	// for submitting colors on server start
	const colorSubmitHandler = async () => {
		try {
			const colorsOject = {
				menu_bar_bg_color: colorStates.menu_bar_bg_color,
				admin_total_foreground_color: colorStates.admin_total_foreground_color,
				admin_total_background_color: colorStates.admin_total_background_color,
				advisors_total_foreground_color:
					colorStates.advisors_total_foreground_color,
				advisors_total_background_color:
					colorStates.advisors_total_background_color,
				students_total_foreground_color:
					colorStates.students_total_foreground_color,
				students_total_background_color:
					colorStates.students_total_background_color,
				notification_up_color: colorStates.notification_up_color,
				notification_down_color: colorStates.notification_down_color,
				dropdown_foreground_color: colorStates.dropdown_foreground_color,
				dropdown_background_color: colorStates.dropdown_background_color
			};

			const response = await fetch("/customize/update-project-colors", {
				method: "PUT",
				body: JSON.stringify(colorsOject),
				headers: { "Content-Type": "application/json" }
			});

			const result = await response.json();

			if (response.status === 200) {
				toast.success(result.message, {
					position: "top-right",
					theme: "colored",
					autoClose: 1500
				});
			} else if (result.error) {
				toast.error(result.error, {
					position: "top-right",
					theme: "colored",
					autoClose: 3000
				});
			}
		} catch (error) {
			toast.error(error.message, {
				position: "top-right",
				theme: "colored",
				autoClose: 3000
			});
		}
	};
	// for submitting colors on server end

	return (
		<>
			<div className="customize-header-container">
				<div className="header">
					<h5>Customize Header</h5>
				</div>

				<div className="customize-header-wrapper">
					{/* for changing header's logo start  */}
					<div className="changing-background-img">
						<div className="cus-header-section-title">
							<i className="fa-solid fa-star-of-david"></i>
							<span>Change project's logo</span>
						</div>

						<div className="preview-container">
							<img
								src={
									getPreviewImg ? getPreviewImg : "/assets/logo/main-logo.png"
								}
								alt="logo"
							/>
						</div>

						{!getPreviewImg ? (
							<div className="input-field-container">
								<label htmlFor="header-logo">Change Logo</label>

								<input
									type="file"
									id="header-logo"
									style={{ display: "none" }}
									accept="image/png, image/gif, image/jpeg"
									onChange={imgHandler}
								/>
							</div>
						) : (
							<div className="btn-container">
								<button
									className="btn btn-danger"
									onClick={() => setPreviewImg("")}
								>
									Cancel
								</button>
								<button className="btn btn-success" onClick={submitHandler}>
									Submit
								</button>
							</div>
						)}
					</div>
					{/* for changing header's logo end */}

					{/* for changing total administrators, advisors & students bg and
					foreground colors start  */}
					<div className="cus-header-changing-color">
						<div className="cus-header-section-title">
							<i className="fa-solid fa-star-of-david"></i>
							<span>Change background & foreground colors</span>
						</div>

						{/* for menubar background-color start  */}
						<div className="changing-color-container">
							<div className="section">
								<span>Menu-bar Icon :</span>

								<div
									id="background-color"
									className="color-picker"
									onClick={() => setMenuBgT(true)}
									ref={menuBgRef}
									style={{
										backgroundColor: colorStates.menu_bar_bg_color
									}}
								>
									{menuBgT && (
										<SketchPicker
											color={colorStates.menu_bar_bg_color}
											className="color-picker-component"
											onChange={(event) =>
												dispatch({
													type: "menu_bar_bg_color",
													payload: event.hex
												})
											}
										/>
									)}
								</div>

								<button
									className="btn btn-success"
									onClick={colorSubmitHandler}
								>
									Save
								</button>
							</div>
						</div>
						{/* for menubar background-color end  */}

						{/* for administrator start  */}
						<div className="changing-color-container">
							<div className="section">
								<span>Total Administrators :</span>

								<div
									id="foreground-color"
									className="color-picker"
									onClick={() => setAdminForeT(true)}
									ref={adminForeRef}
									style={{
										backgroundColor: colorStates.admin_total_foreground_color
									}}
								>
									{adminForeT && (
										<SketchPicker
											color={colorStates.admin_total_foreground_color}
											className="color-picker-component"
											onChange={(event) =>
												dispatch({
													type: "admin-foreground-color",
													payload: event.hex
												})
											}
										/>
									)}
								</div>

								<div
									id="background-color"
									className="color-picker"
									onClick={() => setAdminBgT(true)}
									ref={adminBgRef}
									style={{
										backgroundColor: colorStates.admin_total_background_color
									}}
								>
									{adminBgT && (
										<SketchPicker
											color={colorStates.admin_total_background_color}
											className="color-picker-component"
											onChange={(event) =>
												dispatch({
													type: "admin-background-color",
													payload: event.hex
												})
											}
										/>
									)}
								</div>

								<button
									className="btn btn-success"
									onClick={colorSubmitHandler}
								>
									Save
								</button>
							</div>
						</div>
						{/* for administrator end  */}

						{/* for advisors start  */}
						<div className="changing-color-container">
							<div className="section">
								<span>Total Advisors :</span>

								<div
									id="foreground-color"
									className="color-picker"
									onClick={() => setAdvisorsForeT(true)}
									ref={advisorsForeRef}
									style={{
										backgroundColor: colorStates.advisors_total_foreground_color
									}}
								>
									{advisorsForeT && (
										<SketchPicker
											color={colorStates.advisors_total_foreground_color}
											className="color-picker-component"
											onChange={(event) =>
												dispatch({
													type: "advisors-foreground-color",
													payload: event.hex
												})
											}
										/>
									)}
								</div>

								<div
									id="background-color"
									className="color-picker"
									onClick={() => setAdvisorsBgT(true)}
									ref={advisorsBgRef}
									style={{
										backgroundColor: colorStates.advisors_total_background_color
									}}
								>
									{advisorsBgT && (
										<SketchPicker
											color={colorStates.advisors_total_background_color}
											className="color-picker-component"
											onChange={(event) =>
												dispatch({
													type: "advisors-background-color",
													payload: event.hex
												})
											}
										/>
									)}
								</div>

								<button
									className="btn btn-success"
									onClick={colorSubmitHandler}
								>
									Save
								</button>
							</div>
						</div>
						{/* for advisors end  */}

						{/* for students start  */}
						<div className="changing-color-container">
							<div className="section">
								<span>Total Students :</span>

								<div
									id="foreground-color"
									className="color-picker"
									onClick={() => setStudentsForeT(true)}
									ref={studentsForeRef}
									style={{
										backgroundColor: colorStates.students_total_foreground_color
									}}
								>
									{studentsForeT && (
										<SketchPicker
											color={colorStates.students_total_foreground_color}
											className="color-picker-component"
											onChange={(event) =>
												dispatch({
													type: "students-foreground-color",
													payload: event.hex
												})
											}
										/>
									)}
								</div>

								<div
									id="background-color"
									className="color-picker"
									onClick={() => setStudentsBgT(true)}
									ref={studentsBgRef}
									style={{
										backgroundColor: colorStates.students_total_background_color
									}}
								>
									{studentsBgT && (
										<SketchPicker
											color={colorStates.students_total_background_color}
											className="color-picker-component"
											onChange={(event) =>
												dispatch({
													type: "students-background-color",
													payload: event.hex
												})
											}
										/>
									)}
								</div>

								<button
									className="btn btn-success"
									onClick={colorSubmitHandler}
								>
									Save
								</button>
							</div>
						</div>
						{/* for students end  */}

						{/* for notification gradient color start  */}
						<div className="changing-color-container">
							<div className="section">
								<span>Notification Gradient :</span>

								<div
									id="foreground-color"
									className="color-picker when-notification-up"
									onClick={() => setNotificationUpColT(true)}
									ref={notificationUpColRef}
									style={{
										backgroundColor: colorStates.notification_up_color
									}}
								>
									{notificationUpColT && (
										<SketchPicker
											color={colorStates.notification_up_color}
											className="color-picker-component"
											onChange={(event) =>
												dispatch({
													type: "notification-up-color",
													payload: event.hex
												})
											}
										/>
									)}
								</div>

								<div
									id="background-color"
									className="color-picker when-notification-down"
									onClick={() => setNotificationDownColT(true)}
									ref={notificationDownColRef}
									style={{
										backgroundColor: colorStates.notification_down_color
									}}
								>
									{notificationDownColT && (
										<SketchPicker
											color={colorStates.notification_down_color}
											className="color-picker-component"
											onChange={(event) =>
												dispatch({
													type: "notification-down-color",
													payload: event.hex
												})
											}
										/>
									)}
								</div>

								<button
									className="btn btn-success"
									onClick={colorSubmitHandler}
								>
									Save
								</button>
							</div>
						</div>
						{/* for notification gradient  end  */}

						{/* for dropdown color changing start  */}
						<div className="changing-color-container">
							<div className="section">
								<span>Dropdown :</span>

								<div
									id="foreground-color"
									className="color-picker"
									onClick={() => setDropdownForeT(true)}
									ref={dropdownForeRef}
									style={{
										backgroundColor: colorStates.dropdown_foreground_color
									}}
								>
									{dropdownForeT && (
										<SketchPicker
											color={colorStates.dropdown_foreground_color}
											className="color-picker-component"
											onChange={(event) =>
												dispatch({
													type: "dropdown_foreground_color",
													payload: event.hex
												})
											}
										/>
									)}
								</div>

								<div
									id="background-color"
									className="color-picker"
									onClick={() => setDropdownBgT(true)}
									ref={dropdownBgRef}
									style={{
										backgroundColor: colorStates.dropdown_background_color
									}}
								>
									{dropdownBgT && (
										<SketchPicker
											color={colorStates.dropdown_background_color}
											className="color-picker-component"
											onChange={(event) =>
												dispatch({
													type: "dropdown_background_color",
													payload: event.hex
												})
											}
										/>
									)}
								</div>

								<button
									className="btn btn-success"
									onClick={colorSubmitHandler}
								>
									Save
								</button>
							</div>
						</div>
						{/* for dropdown color changing end  */}
					</div>
					{/* for changing total administrators, advisors & students bg and
					foreground colors end  */}
				</div>
			</div>
		</>
	);
};

export default CusHeaderPage;
