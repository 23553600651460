// external components

// internal components
import { GetContextApi } from "../../../ContextApi";
import "./TabContainer.css";

const WhenStudent = ({ selected, setSelected, currentUser }) => {
	// for getting project's colorStates
	const { colorStates } = GetContextApi();

	return (
		<>
			{/* dashboard start  */}
			<span
				onClick={() => setSelected("dashboard")}
				className={selected === "dashboard" ? "dashboard-active" : ""}
				style={{
					color:
						selected === "dashboard"
							? colorStates.left_slider_options_fore_color
							: ""
				}}
			>
				<i className="bi bi-journal-medical"></i>
				<h5>Dashboard</h5>

				{selected === "dashboard" && (
					<div
						id="highlight-left-sidebar"
						style={{
							backgroundColor: colorStates.highlight_left_sidebar_color
						}}
					></div>
				)}
			</span>
			{/* dashboard end  */}

			{/* chat start  */}
			<span
				onClick={() => setSelected("chat")}
				className={selected === "chat" ? "group-chat-active" : ""}
				style={{
					color:
						selected === "chat"
							? colorStates.left_slider_options_fore_color
							: ""
				}}
			>
				<i className="fa-solid fa-comments"></i>
				<h5>Chat</h5>

				{selected === "chat" && (
					<div
						id="highlight-left-sidebar"
						style={{
							backgroundColor: colorStates.highlight_left_sidebar_color
						}}
					></div>
				)}
			</span>
			{/* chat end  */}

			{/* software-setup start  */}
			{currentUser.role === "administrator" && (
				<span
					className={
						selected === "dashboard" ||
						selected === "chat" ||
						selected === "appointment" ||
						selected === "advisor"
							? "software-setup-inactive"
							: "software-setup-active"
					}
					style={{
						color: !(
							selected === "dashboard" ||
							selected === "chat" ||
							selected === "appointment" ||
							selected === "advisor"
						)
							? colorStates.left_slider_options_fore_color
							: ""
					}}
				>
					<div
						id="software-setup-title"
						onClick={() => setSelected("login-page")}
					>
						<i className="fa-solid fa-screwdriver-wrench"></i>
						<h5>Software Setup</h5>

						{!(
							selected === "dashboard" ||
							selected === "chat" ||
							selected === "appointment" ||
							selected === "advisor"
						) && (
							<div
								id="highlight-left-sidebar"
								style={{
									backgroundColor: colorStates.highlight_left_sidebar_color
								}}
							></div>
						)}
					</div>

					<div className="option-container">
						<div
							className="option"
							onClick={() => setSelected("login-page")}
							id={selected === "login-page" ? "active" : ""}
						>
							<i className="fa-solid fa-circle"></i> <div>Login Page</div>
						</div>

						<div
							className="option"
							onClick={() => setSelected("header")}
							id={selected === "header" ? "active" : ""}
						>
							<i className="fa-solid fa-circle"></i>
							<div>Header</div>
						</div>

						<div
							className="option"
							onClick={() => setSelected("left-sidebar")}
							id={selected === "left-sidebar" ? "active" : ""}
						>
							<i className="fa-solid fa-circle"></i>
							<div>Left Sidebar</div>
						</div>

						<div
							className="option"
							onClick={() => setSelected("footer")}
							id={selected === "footer" ? "active" : ""}
						>
							<i className="fa-solid fa-circle"></i> <div>Footer</div>
						</div>
					</div>
				</span>
			)}

			{/* software-setup end  */}

			{/* create appointment start  */}
			{currentUser.role === "student" && (
				<span
					onClick={() => setSelected("appointment")}
					className={selected === "appointment" ? "appointment-active" : ""}
					style={{
						color:
							selected === "appointment"
								? colorStates.left_slider_options_fore_color
								: ""
					}}
				>
					<i className="bi bi-calendar2-check"></i>
					<h5>Appointment</h5>

					{selected === "appointment" && (
						<div
							id="highlight-left-sidebar"
							style={{
								backgroundColor: colorStates.highlight_left_sidebar_color
							}}
						></div>
					)}
				</span>
			)}
			{/* create appointment end  */}

			{/* advisor-profile start  */}
			{currentUser.role === "student" && (
				<span
					onClick={() => setSelected("advisor")}
					className={selected === "advisor" ? "advisor-active" : ""}
					style={{
						color:
							selected === "advisor"
								? colorStates.left_slider_options_fore_color
								: ""
					}}
				>
					<i className="fa-solid fa-user-tie"></i>

					<h5>My Advisor</h5>

					{selected === "advisor" && (
						<div
							id="highlight-left-sidebar"
							style={{
								backgroundColor: colorStates.highlight_left_sidebar_color
							}}
						></div>
					)}
				</span>
			)}
			{/* advisor-profile end  */}
		</>
	);
};

export default WhenStudent;
