// external components
import { useState } from "react";
import { toast } from "react-toastify";

// internal components
import "./CusLoginPage.css";

const CusLoginPage = () => {
	// for getting input-filed value
	const [getImg, setImg] = useState("");

	// for preview start
	const [getPreviewImg, setPreviewImg] = useState("");

	const imgHandler = (event) => {
		setImg(event.target.files[0]);
		const reader = new FileReader();
		reader.onload = () => {
			if (reader.readyState === 2) {
				setPreviewImg(reader.result);
			}
		};
		reader.readAsDataURL(event.target.files[0]);
	};
	// for preview end

	// upload that img on server start
	const submitHandler = async () => {
		try {
			const formData = new FormData();
			formData.append("file", getImg);

			const response = await fetch("/customize/login-background-img", {
				method: "PUT",
				body: formData
			});

			const result = await response.json();

			if (response.status === 200) {
				toast.success(result.message, {
					position: "top-right",
					theme: "colored",
					autoClose: 1500
				});

				setPreviewImg("");
			} else {
				toast.error(result.error, {
					position: "top-right",
					theme: "colored",
					autoClose: 3000
				});
			}
		} catch (error) {
			toast.error(error.message, {
				position: "top-right",
				theme: "colored",
				autoClose: 3000
			});
		}
	};
	// upload that img on server end

	return (
		<>
			<div className="customize-login-page-container">
				<div className="header">
					<h5>Customize Login Page</h5>
				</div>

				<div className="customize-login-page-wrapper">
					{/* for changing login page background image start  */}
					<div className="changing-background-img">
						<div className="title">
							<i className="fa-solid fa-star-of-david"></i>
							<span>Change login page's background image</span>
						</div>

						<div className="preview-container">
							<img
								src={
									getPreviewImg
										? getPreviewImg
										: "/assets/images/login-background.jpg"
								}
								alt="login-background"
							/>
						</div>

						{!getPreviewImg ? (
							<div className="input-field-container">
								<label htmlFor="login-background-img">
									Change Background Image
								</label>

								<input
									type="file"
									id="login-background-img"
									style={{ display: "none" }}
									accept="image/png, image/gif, image/jpeg"
									onChange={imgHandler}
								/>
							</div>
						) : (
							<div className="btn-container">
								<button
									className="btn btn-danger"
									onClick={() => setPreviewImg("")}
								>
									Cancel
								</button>
								<button className="btn btn-success" onClick={submitHandler}>
									Submit
								</button>
							</div>
						)}
					</div>
					{/* for changing login page background image end */}
				</div>
			</div>
		</>
	);
};

export default CusLoginPage;
