// external components
import { useEffect, useRef, useState } from "react";
import { SketchPicker } from "react-color";
import { toast } from "react-toastify";

// internal components
import { GetContextApi } from "../../../../ContextApi";
import "./CusLeftSidebar.css";

const CusLeftSidebar = () => {
	// for getting projects all colors
	const { colorStates, dispatch } = GetContextApi();

	// for all color-picker toggle start
	const [userNameColorT, setUserNameColorT] = useState(false);
	const [userIdColorT, setUserIdColorT] = useState(false);
	const [highlightBarColorT, setHighlightBarColorT] = useState(false);
	const [optionsForeColorT, setoptionsForeColorT] = useState(false);
	// for all color-picker toggle end

	// for close color-picker start

	// for user name foreground-color
	const userNameColorRef = useRef();

	const handleClickOutsideUserNameColor = (e) => {
		if (!userNameColorRef.current?.contains(e.target)) {
			setUserNameColorT(false);
		}
	};

	useEffect(() => {
		document.addEventListener("mousedown", handleClickOutsideUserNameColor);
		return () =>
			document.removeEventListener(
				"mousedown",
				handleClickOutsideUserNameColor
			);
	}, []);

	// for user id foreground-color
	const userIdColorRef = useRef();

	const handleClickOutsideUserIdColor = (e) => {
		if (!userIdColorRef.current?.contains(e.target)) {
			setUserIdColorT(false);
		}
	};

	useEffect(() => {
		document.addEventListener("mousedown", handleClickOutsideUserIdColor);
		return () =>
			document.removeEventListener("mousedown", handleClickOutsideUserIdColor);
	}, []);

	// for highlight-bar background-color
	const highlightRef = useRef();

	const handleClickOutsideHighlight = (e) => {
		if (!highlightRef.current?.contains(e.target)) {
			setHighlightBarColorT(false);
		}
	};

	useEffect(() => {
		document.addEventListener("mousedown", handleClickOutsideHighlight);
		return () =>
			document.removeEventListener("mousedown", handleClickOutsideHighlight);
	}, []);

	// for options background-color
	const optionsForeRef = useRef();

	const handleClickOutsideOptionsFore = (e) => {
		if (!optionsForeRef.current?.contains(e.target)) {
			setoptionsForeColorT(false);
		}
	};

	useEffect(() => {
		document.addEventListener("mousedown", handleClickOutsideOptionsFore);
		return () =>
			document.removeEventListener("mousedown", handleClickOutsideOptionsFore);
	}, []);
	// for close color-picker end

	// for submitting colors on server start
	const colorSubmitHandler = async () => {
		try {
			const colorsOject = {
				user_name_color: colorStates.user_name_color,
				user_id_color: colorStates.user_id_color,
				highlight_left_sidebar_color: colorStates.highlight_left_sidebar_color,
				left_slider_options_fore_color:
					colorStates.left_slider_options_fore_color
			};

			const response = await fetch("/customize/update-project-colors", {
				method: "PUT",
				body: JSON.stringify(colorsOject),
				headers: { "Content-Type": "application/json" }
			});

			const result = await response.json();

			if (response.status === 200) {
				toast.success(result.message, {
					position: "top-right",
					theme: "colored",
					autoClose: 1500
				});
			} else if (result.error) {
				toast.error(result.error, {
					position: "top-right",
					theme: "colored",
					autoClose: 3000
				});
			}
		} catch (error) {
			toast.error(error.message, {
				position: "top-right",
				theme: "colored",
				autoClose: 3000
			});
		}
	};
	// for submitting colors on server end

	return (
		<>
			<div className="customize-left-sidebar-container">
				<div className="header">
					<h5>Customize Left-Sidebar</h5>
				</div>

				<div className="customize-left-sidebar-wrapper">
					{/* for changing user-name, id & left-sidebar hover color start  */}
					<div className="cus-left-sidebar-changing-color">
						<div className="cus-header-section-title">
							<i className="fa-solid fa-star-of-david"></i>
							<span>Change background & foreground colors</span>
						</div>

						{/* for username color changing start  */}
						<div className="changing-color-container">
							<div className="section">
								<span>User Name :</span>

								<div
									id="foreground-color"
									className="color-picker"
									onClick={() => setUserNameColorT(true)}
									ref={userNameColorRef}
									style={{
										backgroundColor: colorStates.user_name_color
									}}
								>
									{userNameColorT && (
										<SketchPicker
											color={colorStates.user_name_color}
											className="when-user-name"
											onChange={(event) =>
												dispatch({
													type: "user-name-color",
													payload: event.hex
												})
											}
										/>
									)}
								</div>

								<button
									className="btn btn-success"
									onClick={colorSubmitHandler}
								>
									Save
								</button>
							</div>
						</div>
						{/* for username color changing end  */}

						{/* for user id color changing start  */}
						<div className="changing-color-container">
							<div className="section">
								<span>User Id :</span>

								<div
									id="foreground-color"
									className="color-picker"
									onClick={() => setUserIdColorT(true)}
									ref={userIdColorRef}
									style={{
										backgroundColor: colorStates.user_id_color
									}}
								>
									{userIdColorT && (
										<SketchPicker
											color={colorStates.user_id_color}
											className="when-user-name"
											onChange={(event) =>
												dispatch({
													type: "user-id-color",
													payload: event.hex
												})
											}
										/>
									)}
								</div>

								<button
									className="btn btn-success"
									onClick={colorSubmitHandler}
								>
									Save
								</button>
							</div>
						</div>
						{/* for user id color changing end  */}

						{/* for left-sidebar highlight color changing start  */}
						<div className="changing-color-container">
							<div className="section">
								<span>Highlight Bar Color :</span>

								<div
									id="background-color"
									className="color-picker"
									onClick={() => setHighlightBarColorT(true)}
									ref={highlightRef}
									style={{
										backgroundColor: colorStates.highlight_left_sidebar_color
									}}
								>
									{highlightBarColorT && (
										<SketchPicker
											color={colorStates.highlight_left_sidebar_color}
											className="when-user-name"
											onChange={(event) =>
												dispatch({
													type: "highlight_left_sidebar_color",
													payload: event.hex
												})
											}
										/>
									)}
								</div>

								<button
									className="btn btn-success"
									onClick={colorSubmitHandler}
								>
									Save
								</button>
							</div>
						</div>
						{/* for left-sidebar highlight color changing end  */}

						{/* for left-sidebar options foreground-color changing start  */}
						<div className="changing-color-container">
							<div className="section">
								<span>Options Foreground Color :</span>

								<div
									id="foreground-color"
									className="color-picker"
									onClick={() => setoptionsForeColorT(true)}
									ref={optionsForeRef}
									style={{
										backgroundColor: colorStates.left_slider_options_fore_color
									}}
								>
									{optionsForeColorT && (
										<SketchPicker
											color={colorStates.left_slider_options_fore_color}
											className="when-user-name"
											onChange={(event) =>
												dispatch({
													type: "left_slider_options_fore_color",
													payload: event.hex
												})
											}
										/>
									)}
								</div>

								<button
									className="btn btn-success"
									onClick={colorSubmitHandler}
								>
									Save
								</button>
							</div>
						</div>
						{/* for left-sidebar options background-color changing end  */}
					</div>
					{/* for changing user-name, id & left-sidebar hover color end  */}
				</div>
			</div>
		</>
	);
};

export default CusLeftSidebar;
