// external components
import { useEffect, useRef, useState } from "react";
import NotificationBadge, { Effect } from "react-notification-badge";
import { toast } from "react-toastify";
import sortArray from "sort-array";
import TimeAgo from "timeago-react";

// internal components
import { GetContextApi } from "../../ContextApi";
import { Viewport } from "../Viewport";
import "./Navbar.css";

const Navbar = ({
	currentUser,
	registerT,
	setRegisterT,
	setTotalValue,
	setProfileT,
	created,
	setAppDisplay,
	setMessageId,
	setSelected
}) => {
	// for get socket connection
	const {
		mySocket,
		notifiUpdate,
		notifiUpdateAdmin,
		updateCount,
		colorStates
	} = GetContextApi();

	// for get all notifications
	const [notifications, setNotifications] = useState("");
	const [messageN, setMessageN] = useState("");
	const [appointmentN, setAppointmentN] = useState("");

	// for update appointment notification count
	const [checked, setChecked] = useState(false);

	// for get socket notification
	const [socketN, setSocketN] = useState("");

	// for profile & log-out dropdown
	const [logoutT, setLogoutT] = useState(false);

	// for notification dropdown
	const [appointmentN_T, setAppointmentN_T] = useState("");
	const [messageN_T, setMessageN_T] = useState("");

	// for setting notification hover color
	const [createNewHover, setCreateNewHover] = useState(false);
	const [messageHover, setMessageHover] = useState(false);
	const [notificationHover, setNotificationHover] = useState(false);

	// for setting dropdown hover color
	const [myProfileHover, setMyProfileHover] = useState(false);
	const [createUserHover, setCreateUserHover] = useState(false);
	const [totalAdvisorsHover, setTotalAdvisorsHover] = useState(false);
	const [totalStudentsHover, setTotalStudentsHover] = useState(false);
	const [logoutHover, setLogoutHover] = useState(false);

	// for total-counter
	const [totalAdministrators, setTotalAdministrators] = useState(0);
	const [totalAdvisors, setTotalAdvisors] = useState(0);
	const [totalStudents, setTotalStudents] = useState(0);

	// for close message dropdown from outside-click start
	const messageRef = useRef();

	const handleClickOutsideMessage = (e) => {
		if (!messageRef.current?.contains(e.target)) {
			setMessageN_T(false);
		}
	};

	useEffect(() => {
		document.addEventListener("mousedown", handleClickOutsideMessage);
		return () =>
			document.removeEventListener("mousedown", handleClickOutsideMessage);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	// for close message dropdown from outside-click end

	// for close appointment dropdown from outside-click start
	const appointmentRef = useRef();

	const handleClickOutsideAppt = (e) => {
		if (!appointmentRef.current?.contains(e.target)) {
			setAppointmentN_T(false);
		}
	};

	useEffect(() => {
		document.addEventListener("mousedown", handleClickOutsideAppt);
		return () =>
			document.removeEventListener("mousedown", handleClickOutsideAppt);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	// for close appointment dropdown from outside-click end

	// for close profile dropdown from outside-click start
	const profileRef = useRef();

	const handleClickOutside = (e) => {
		if (!profileRef.current?.contains(e.target)) {
			setLogoutT(false);
		}
	};

	useEffect(() => {
		document.addEventListener("mousedown", handleClickOutside);
		return () => document.removeEventListener("mousedown", handleClickOutside);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	// for close profile dropdown from outside-click end

	// get currentUser all notifications start
	useEffect(() => {
		(async () => {
			try {
				const response = await fetch("/notification");

				const result = await response.json();

				if (response.status === 200) {
					setNotifications(result ? result : "");
				} else if (result.error) {
					toast.error(result.error, {
						position: "top-right",
						theme: "colored",
						autoClose: 3000
					});
				}
			} catch (error) {
				toast.error(error.message, {
					position: "top-right",
					theme: "colored",
					autoClose: 3000
				});
			}
		})();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [checked, updateCount]);
	// get currentUser all notifications end

	// for get notification through socket start
	useEffect(() => {
		if (currentUser.role === "administrator") {
			mySocket?.emit("join_room_notification", "administrator");
			mySocket?.on("receive_notification", (notification) => {
				setSocketN(notification);
			});
		} else {
			mySocket?.emit("join_room_notification", currentUser._id);
			mySocket?.on("receive_notification", (notification) => {
				setSocketN(notification);
			});

			mySocket?.on("receive_group_notification", (notification) => {
				setSocketN(notification);
			});
		}
	}, [mySocket, currentUser]);
	// for get notification through socket end

	useEffect(() => {
		if (socketN) {
			setNotifications([...notifications, socketN]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [socketN]);

	// initialize notification start
	useEffect(() => {
		if (notifications) {
			setMessageN(notifications?.filter((value) => value.kind === "message"));

			if (currentUser.role === "administrator") {
				setAppointmentN(
					notifications?.filter(
						(value) =>
							value.kind === "create" ||
							value.kind === "reply" ||
							value.kind === "apptDate" ||
							value.kind === "status"
					)
				);
			} else if (currentUser.role === "advisor") {
				setAppointmentN(
					notifications?.filter(
						(value) => value.kind === "create" || value.kind === "reply"
					)
				);
			} else if (currentUser.role === "student") {
				setAppointmentN(
					notifications?.filter(
						(value) =>
							value.kind === "apptDate" ||
							value.kind === "status" ||
							value.kind === "reply"
					)
				);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [notifications, currentUser]);
	// initialize notification end

	// for notification update when admin start
	useEffect(() => {
		(async () => {
			if (typeof notifiUpdateAdmin === "object") {
				try {
					const response = await fetch("/notification", {
						method: "PUT",
						body: JSON.stringify(notifiUpdateAdmin),
						headers: { "Content-Type": "application/json" }
					});

					const result = await response.json();

					if (response.status === 200) {
						return;
					} else if (result.error) {
						toast.error(result.error, {
							position: "top-right",
							theme: "colored",
							autoClose: 3000
						});
					}
				} catch (error) {
					toast.error(error.message, {
						position: "top-right",
						theme: "colored",
						autoClose: 3000
					});
				}
			} else {
				return;
			}
		})();
	}, [notifiUpdateAdmin]);
	// for notification update admin  end

	// for notification update when advisor & student start
	useEffect(() => {
		(async () => {
			if (typeof notifiUpdate === "object") {
				try {
					const response = await fetch("/notification", {
						method: "PUT",
						body: JSON.stringify(notifiUpdate),
						headers: { "Content-Type": "application/json" }
					});

					const result = await response.json();

					if (response.status === 200) {
						return;
					} else if (result.error) {
						toast.error(result.error, {
							position: "top-right",
							theme: "colored",
							autoClose: 3000
						});
					}
				} catch (error) {
					toast.error(error.message, {
						position: "top-right",
						theme: "colored",
						autoClose: 3000
					});
				}
			} else {
				return;
			}
		})();
	}, [notifiUpdate]);
	// for notification update advisor & student  end

	// make all read handler start
	const makeAllReadHandler = async () => {
		try {
			setChecked(Date.now());
			const response = await fetch("/notification/read");

			const result = await response.json();

			if (response.status === 200) {
				return;
			} else if (result.error) {
				toast.error(result.error, {
					position: "top-right",
					theme: "colored",
					autoClose: 3000
				});
			}
		} catch (error) {
			toast.error(error.message, {
				position: "top-right",
				theme: "colored",
				autoClose: 3000
			});
		}
	};
	// make all read handler end

	// for get total count of administrators, advisors & students start
	useEffect(() => {
		if (currentUser?.role === "administrator") {
			(async () => {
				// for count of administrators
				try {
					const response = await fetch("/user/administrator-list");
					const result = await response.json();

					if (response.status === 200) {
						setTotalAdministrators(result?.length);
					} else if (result.error) {
						toast.error(result.error, {
							position: "top-right",
							theme: "colored",
							autoClose: 3000
						});
					}
				} catch (error) {
					toast.error(error.message, {
						position: "top-right",
						theme: "colored",
						autoClose: 3000
					});
				}

				// for count of advisors
				try {
					const response = await fetch("/user/advisor-list");
					const result = await response.json();

					if (response.status === 200) {
						setTotalAdvisors(result?.length);
					} else if (result.error) {
						toast.error(result.error, {
							position: "top-right",
							theme: "colored",
							autoClose: 3000
						});
					}
				} catch (error) {
					toast.error(error.message, {
						position: "top-right",
						theme: "colored",
						autoClose: 3000
					});
				}

				// for count of students
				try {
					const response = await fetch("/user/student-list");
					const result = await response.json();

					if (response.status === 200) {
						setTotalStudents(result?.length);
					} else if (result.error) {
						toast.error(result.error, {
							position: "top-right",
							theme: "colored",
							autoClose: 3000
						});
					}
				} catch (error) {
					toast.error(error.message, {
						position: "top-right",
						theme: "colored",
						autoClose: 3000
					});
				}
			})();
		}
	}, [created, currentUser]);
	// for get total count of advisors & students end

	// left-sidebar for small device start
	const [isSidebarOpen, setIsSidebarOpen] = useState(false);

	// software-setup option toggle
	const [isDisplayOption, setIsDisplayOption] = useState(false);

	// for checking mobile view-port achieve or not
	const isMobile = Viewport("(max-width:550px)", true);

	// for close left-sidebar when click outside start
	const leftSidebarRef = useRef();

	const handleClickOutsideLeftSidebar = (e) => {
		if (!leftSidebarRef.current?.contains(e.target)) {
			setIsSidebarOpen(false);
		}
	};

	useEffect(() => {
		document.addEventListener("mousedown", handleClickOutsideLeftSidebar);
		return () =>
			document.removeEventListener("mousedown", handleClickOutsideLeftSidebar);
	}, []);
	// for close left-sidebar when click outside end
	// left-sidebar for small device end

	return (
		<>
			<div className="container-fluid navbar-main-container">
				<div className="row col-11 navbar-container">
					<div className="col-xl-3 col-md-6 col-5 p-0">
						<div className="left">
							{/* for left-sidebar for small device start  */}
							<div className="left-sidebar-container">
								<ul
									className={
										isSidebarOpen ? "nav-links nav-active" : "nav-links"
									}
									ref={leftSidebarRef}
								>
									<img
										src="/assets/logo/main-logo.png"
										alt="logo"
										className="img-fluid within-left-sidebar-logo"
										id="main-logo"
										onClick={() => {
											setSelected("dashboard");
											setIsSidebarOpen(false);
										}}
									/>

									<div id="for-separation"></div>

									<li
										className={isSidebarOpen ? "ani-link-1" : null}
										onClick={() => {
											setSelected("dashboard");
											setIsSidebarOpen(false);
										}}
									>
										<i className="bi bi-journal-medical"></i>
										<span>Dashboard</span>
									</li>

									<li
										className={isSidebarOpen ? "ani-link-2" : null}
										onClick={() => {
											setSelected("chat");
											setIsSidebarOpen(false);
										}}
									>
										<i className="fa-solid fa-comments"></i>
										<span>Chat</span>
									</li>

									<li
										className={
											isSidebarOpen ? "ani-link-3 software-setup-item" : null
										}
										onClick={() => setIsDisplayOption(!isDisplayOption)}
										id={isDisplayOption ? "active" : ""}
									>
										<span id="software-setup-content">
											<i className="fa-solid fa-screwdriver-wrench"></i>
											<span>Software Setup</span>
										</span>

										{isDisplayOption && (
											<div className="option-container">
												<div
													className="option"
													onClick={() => {
														setSelected("login-page");
														setIsSidebarOpen(false);
													}}
												>
													<i className="fa-solid fa-circle"></i>{" "}
													<div>Login Page</div>
												</div>

												<div
													className="option"
													onClick={() => {
														setSelected("header");
														setIsSidebarOpen(false);
													}}
												>
													<i className="fa-solid fa-circle"></i>
													<div>Header</div>
												</div>

												<div
													className="option"
													onClick={() => {
														setSelected("left-sidebar");
														setIsSidebarOpen(false);
													}}
												>
													<i className="fa-solid fa-circle"></i>
													<div>Left Sidebar</div>
												</div>

												<div
													className="option"
													onClick={() => {
														setSelected("footer");
														setIsSidebarOpen(false);
													}}
												>
													<i className="fa-solid fa-circle"></i>{" "}
													<div>Footer</div>
												</div>
											</div>
										)}
									</li>

									<li
										className={isSidebarOpen ? "ani-link-4" : null}
										onClick={() => {
											setSelected("appointment");
											setIsSidebarOpen(false);
										}}
									>
										<i className="bi bi-calendar2-check"></i>
										<span>Appointment</span>
									</li>

									<li
										className={isSidebarOpen ? "ani-link-5" : null}
										onClick={() => {
											setSelected("advisor");
											setIsSidebarOpen(false);
										}}
									>
										<i className="fa-solid fa-user-tie"></i>
										<span>My Advisor</span>
									</li>
								</ul>

								<div
									className="burger"
									onClick={() => setIsSidebarOpen(!isSidebarOpen)}
									id={isMobile && isSidebarOpen ? "shift-right" : ""}
								>
									<div
										className={isSidebarOpen ? "line-1" : null}
										style={{ backgroundColor: colorStates.menu_bar_bg_color }}
									></div>
									<div
										className={isSidebarOpen ? "line-2" : null}
										style={{ backgroundColor: colorStates.menu_bar_bg_color }}
									></div>
									<div
										className={isSidebarOpen ? "line-3" : null}
										style={{ backgroundColor: colorStates.menu_bar_bg_color }}
									></div>
								</div>
							</div>
							{/* for left-sidebar for small device end */}

							<img
								src="/assets/logo/main-logo.png"
								alt="logo"
								className="img-fluid"
								id="main-logo"
								style={{
									visibility: isMobile && isSidebarOpen ? "hidden" : "visible"
								}}
								onClick={() => {
									setSelected("dashboard");
								}}
							/>
						</div>
					</div>

					<div className="col-xl-6 d-none d-xl-flex p-0">
						{currentUser?.role === "administrator" && (
							<div className="middle">
								<div
									className="total"
									onClick={() =>
										totalAdvisors && setTotalValue("List Of Administrators")
									}
									style={{
										color: colorStates.admin_total_foreground_color,
										backgroundColor: colorStates.admin_total_background_color
									}}
								>
									Administrators : &nbsp; <span>{totalAdministrators}</span>
								</div>

								<div
									className="total"
									onClick={() =>
										totalAdvisors && setTotalValue("List Of Advisors")
									}
									style={{
										color: colorStates.advisors_total_foreground_color,
										backgroundColor: colorStates.advisors_total_background_color
									}}
								>
									Advisors : &nbsp; <span>{totalAdvisors}</span>
								</div>

								<div
									className="total"
									onClick={() =>
										totalStudents && setTotalValue("List Of Students")
									}
									style={{
										color: colorStates.students_total_foreground_color,
										backgroundColor: colorStates.students_total_background_color
									}}
								>
									Students : &nbsp; <span>{totalStudents}</span>
								</div>
							</div>
						)}
					</div>

					<div className="col-xl-3 col-md-5 col-7 p-0 nav-right-container">
						<div className="right">
							<div className="icon-style">
								<span
									className={
										currentUser.role === "administrator" ? "" : "visible"
									}
									id="create-new-user"
									onClick={() => setRegisterT(!registerT)}
								>
									<i
										className="bi bi-plus-circle-dotted"
										onMouseOver={() => setCreateNewHover(true)}
										onMouseOut={() => setCreateNewHover(false)}
										style={{
											background: createNewHover
												? `linear-gradient(${colorStates.notification_up_color},${colorStates.notification_down_color})`
												: ""
										}}
									></i>
								</span>

								<span>
									<i
										className="bi bi-chat-heart"
										onClick={() => {
											setMessageN_T(!messageN_T);
											notifications && makeAllReadHandler();
										}}
										onMouseOver={() => setMessageHover(true)}
										onMouseOut={() => setMessageHover(false)}
										style={{
											background: messageHover
												? `linear-gradient(${colorStates.notification_up_color},${colorStates.notification_down_color})`
												: ""
										}}
									>
										<NotificationBadge
											count={
												messageN &&
												(currentUser.role === "administrator"
													? 0
													: messageN.filter((value) => value.isRead === false)
															?.length)
											}
											effect={Effect.SCALE}
											className="notification-count"
										/>
									</i>

									{/* for message notification  */}
									{messageN_T && messageN.length > 0 ? (
										<div
											ref={messageRef}
											className="notification-container"
											id="for-message"
											data-aos="fade-left"
											data-aos-delay="0"
										>
											{sortArray(messageN, {
												by: "time",
												order: "desc"
											}).map((value, index) => {
												return (
													<div
														className="notification-display"
														key={index}
														onClick={() => {
															setMessageId(value.from_where);
															setMessageN_T(false);
															setSelected("chat");
														}}
													>
														<img
															src={`uploads/profile-img/${value.sender_profile}`}
															alt="img"
															className="profile-img img-fluid"
														/>

														<div className="last-message">
															<span>{value.sender_name}</span>

															<h6
																style={{
																	wordSpacing: "0",
																	textAlign: "start",
																	"align-self": "flex-start"
																}}
															>
																<span>{value.last_message}</span>
															</h6>
														</div>
														<div className="notification-time">
															<TimeAgo datetime={value.time} />
														</div>
													</div>
												);
											})}
										</div>
									) : (
										messageN_T && (
											<div
												className="no-notification"
												data-aos="fade-left"
												data-aos-delay="0"
											>
												<h6>No Message</h6>
											</div>
										)
									)}
								</span>

								<span>
									<i
										className="bi bi-bell-fill"
										onClick={() => {
											setAppointmentN_T(!appointmentN_T);
											notifications && makeAllReadHandler();
										}}
										onMouseOver={() => setNotificationHover(true)}
										onMouseOut={() => setNotificationHover(false)}
										style={{
											background: notificationHover
												? `linear-gradient(${colorStates.notification_up_color},${colorStates.notification_down_color})`
												: ""
										}}
									>
										<NotificationBadge
											count={
												appointmentN &&
												appointmentN.filter((value) => value.isRead === false)
													?.length
											}
											effect={Effect.SCALE}
											className="notification-count"
										/>
									</i>

									{/* for appointment notification start */}
									{appointmentN_T && appointmentN.length > 0 ? (
										<div
											ref={appointmentRef}
											className="notification-container"
											data-aos="fade-left"
											data-aos-delay="0"
											id="for-appointment"
										>
											{sortArray(appointmentN, {
												by: "time",
												order: "desc"
											}).map((value, index) => {
												return (
													<div
														className="notification-display"
														key={index}
														onClick={() => {
															setAppDisplay(value.from_where);
															setAppointmentN_T(false);
														}}
													>
														<img
															src={`uploads/profile-img/${value.sender_profile}`}
															alt="img"
															className="profile-img img-fluid"
														/>

														<div>
															<h6 style={{ " max-width": "120px" }}>
																{value.sender_name}
															</h6>
															<span>{value.text}</span>
														</div>
														<div className="notification-time">
															<TimeAgo datetime={value.time} />
														</div>
													</div>
												);
											})}
										</div>
									) : (
										appointmentN_T && (
											<div
												className="no-notification"
												data-aos="fade-left"
												data-aos-delay="0"
											>
												<h6>Empty Notification</h6>
											</div>
										)
									)}
									{/* for appointment notification end */}
								</span>
							</div>

							<span className="nav-profile">
								<img
									src={`uploads/profile-img/${currentUser.profile_img}`}
									alt="profile img"
									className="profile-img img-fluid"
									onClick={() => setLogoutT(!logoutT)}
								/>
								{logoutT && (
									<ul ref={profileRef}>
										<li
											onClick={() => {
												setProfileT("profile");
												setLogoutT(false);
											}}
											onMouseOver={() => setMyProfileHover(true)}
											onMouseOut={() => setMyProfileHover(false)}
											style={{
												color: myProfileHover
													? colorStates.dropdown_foreground_color
													: "",
												backgroundColor: myProfileHover
													? colorStates.dropdown_background_color
													: ""
											}}
										>
											<i className="bi bi-person-circle"></i>
											<span>My Profile</span>
										</li>

										{currentUser.role === "administrator" && (
											<li
												id="create"
												onClick={() => {
													setRegisterT(!registerT);
													setLogoutT(false);
												}}
												onMouseOver={() => setCreateUserHover(true)}
												onMouseOut={() => setCreateUserHover(false)}
												style={{
													color: createUserHover
														? colorStates.dropdown_foreground_color
														: "",
													backgroundColor: createUserHover
														? colorStates.dropdown_background_color
														: ""
												}}
											>
												<i className="bi bi-plus-circle-dotted"></i>
												<span>Create User</span>
											</li>
										)}

										{currentUser.role === "administrator" && (
											<li
												className="total-values"
												onClick={() => {
													totalAdvisors && setTotalValue("List Of Advisors");
													setLogoutT(false);
												}}
												onMouseOver={() => setTotalAdvisorsHover(true)}
												onMouseOut={() => setTotalAdvisorsHover(false)}
												style={{
													color: totalAdvisorsHover
														? colorStates.dropdown_foreground_color
														: "",
													backgroundColor: totalAdvisorsHover
														? colorStates.dropdown_background_color
														: ""
												}}
											>
												<i>👨‍💼</i>
												<span>
													{totalAdvisors > 1 ? "Advisors" : "Advisor"}
													<h6>{totalAdvisors}</h6>
												</span>
											</li>
										)}

										{currentUser.role === "administrator" && (
											<li
												className="total-values"
												onClick={() => {
													totalStudents && setTotalValue("List Of Students");
													setLogoutT(false);
												}}
												onMouseOver={() => setTotalStudentsHover(true)}
												onMouseOut={() => setTotalStudentsHover(false)}
												style={{
													color: totalStudentsHover
														? colorStates.dropdown_foreground_color
														: "",
													backgroundColor: totalStudentsHover
														? colorStates.dropdown_background_color
														: ""
												}}
											>
												<i>👨‍🎓</i>
												<span>
													{totalStudents > 1 ? "Students" : "Student"}
													<h6>{totalStudents}</h6>
												</span>
											</li>
										)}

										<li
											onClick={() => {
												setProfileT("logout");
												setLogoutT(false);
											}}
											onMouseOver={() => setLogoutHover(true)}
											onMouseOut={() => setLogoutHover(false)}
											style={{
												color: logoutHover
													? colorStates.dropdown_foreground_color
													: "",
												backgroundColor: logoutHover
													? colorStates.dropdown_background_color
													: ""
											}}
										>
											<i className="fa-solid fa-right-from-bracket"></i>
											<span>Log out</span>
										</li>
									</ul>
								)}
							</span>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Navbar;
