// external components
import {
	createContext,
	useContext,
	useEffect,
	useReducer,
	useState
} from "react";
import io from "socket.io-client";

// internal components
import { Viewport } from "./components/Viewport";

const rootContext = createContext(null);

const ContextHandler = ({ children }) => {
	// establish connection with socket-server
	const [mySocket, setMySocket] = useState();

	useEffect(() => {
		setMySocket(io.connect("http://localhost:4000"));

		// setMySocket(io.connect("https://nubscms.deltadevbd.com"));
	}, []);

	// for get & set current user
	const [currentUser, setCurrentUser] = useState("");

	// for when submitted appointment, refetching data in dashboard
	const [isSubmitted, setIsSubmitted] = useState("");

	// for update notifications for advisor & student
	const [notifiUpdate, setNotifiUpdate] = useState("");

	// for update notifications for admin
	const [notifiUpdateAdmin, setNotifiUpdateAdmin] = useState("");

	// for notification count update from massage-box
	const [updateCount, setUpdateCount] = useState("");

	// when message-box visible => menubar hidden
	const [hideMenu, setHideMenu] = useState("");

	// for checking mobile view-port achieve or not
	const isMobile = Viewport("(max-width:768px)", true);

	// for colorPicker state start
	const initialState = {
		menu_bar_bg_color: "",
		admin_total_foreground_color: "",
		admin_total_background_color: "",
		advisors_total_foreground_color: "",
		advisors_total_background_color: "",
		students_total_foreground_color: "",
		students_total_background_color: "",
		notification_up_color: "",
		notification_down_color: "",
		user_name_color: "",
		user_id_color: "",
		highlight_left_sidebar_color: "",
		left_slider_options_fore_color: "",
		dropdown_foreground_color: "",
		dropdown_background_color: ""
	};

	const reducerFunction = (state, { type, payload }) => {
		switch (type) {
			case "getAllColors":
				return payload;

			case "menu_bar_bg_color":
				return { ...state, menu_bar_bg_color: payload };

			case "admin-foreground-color":
				return { ...state, admin_total_foreground_color: payload };

			case "admin-background-color":
				return { ...state, admin_total_background_color: payload };

			case "advisors-foreground-color":
				return { ...state, advisors_total_foreground_color: payload };

			case "advisors-background-color":
				return { ...state, advisors_total_background_color: payload };

			case "students-foreground-color":
				return { ...state, students_total_foreground_color: payload };

			case "students-background-color":
				return { ...state, students_total_background_color: payload };

			case "notification-up-color":
				return { ...state, notification_up_color: payload };

			case "notification-down-color":
				return { ...state, notification_down_color: payload };

			case "user-name-color":
				return { ...state, user_name_color: payload };

			case "user-id-color":
				return { ...state, user_id_color: payload };

			case "highlight_left_sidebar_color":
				return { ...state, highlight_left_sidebar_color: payload };

			case "left_slider_options_fore_color":
				return { ...state, left_slider_options_fore_color: payload };

			case "dropdown_foreground_color":
				return { ...state, dropdown_foreground_color: payload };

			case "dropdown_background_color":
				return { ...state, dropdown_background_color: payload };

			default:
				return state;
		}
	};

	const [colorStates, dispatch] = useReducer(reducerFunction, initialState);
	// for colorPicker state end

	return (
		<>
			<rootContext.Provider
				value={{
					mySocket,
					currentUser,
					setCurrentUser,
					isSubmitted,
					setIsSubmitted,
					notifiUpdate,
					setNotifiUpdate,
					notifiUpdateAdmin,
					setNotifiUpdateAdmin,
					updateCount,
					setUpdateCount,
					hideMenu,
					setHideMenu,
					isMobile,
					colorStates,
					dispatch
				}}
			>
				{children}
			</rootContext.Provider>
		</>
	);
};

export const GetContextApi = () => {
	return useContext(rootContext);
};

export default ContextHandler;
