//external components

// internal components
import { GetContextApi } from "../../ContextApi";
import "./LeftSidebar.css";
import TabContainer from "./TabContainer/TabContainer";

const LeftSidebar = ({ currentUser, selected, setSelected }) => {
	// for getting project's color-picker
	const { colorStates } = GetContextApi();

	return (
		<>
			<div className="current-user d-none d-lg-flex">
				<img
					src={`uploads/profile-img/${currentUser.profile_img}`}
					alt="profile-img"
					className="img-fluid"
				/>
				<div className="info">
					<h4 style={{ color: colorStates.user_name_color }}>
						{currentUser.name}
					</h4>
					<div>
						<h6>ID :</h6>{" "}
						<span style={{ color: colorStates.user_id_color }}>
							{currentUser.id}
						</span>
					</div>
				</div>
			</div>

			<div className="tab-container d-none d-lg-block">
				<TabContainer
					selected={selected}
					setSelected={setSelected}
					currentUser={currentUser}
				/>
			</div>
		</>
	);
};

export default LeftSidebar;
